#supportsigninbutton{
    background: linear-gradient(to left, #4f43e8 0%, #934bf3 100%);
    margin-top: 80px;
    width: 90%;
    max-width: 100%;
    height: 52px;
}


#headingone{
    width: 280px;
    height: 34px;
    margin: 0 609.5px 15px 12.5px;
    font-family: 'Montserrat';
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
}

#subheadingone{
    width: 50%;
    height: 22px;
    margin: 56px 736.5px 15px 12.5px;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
}

#boxpara{
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #928a9f;
    width: 60%;
    margin-top: 8px;
    margin-left: 16px;
}


#hrline{
    width: 97%;
    height: 0;
    border: solid 1px #e3e3e3;
 
}


.bgimage {
    background-repeat: 'no-repeat';
    }
    
   

 @media screen and (min-width: 0px) and (max-width: 899px) {
        #gridreverseformobile {
       
            display: flex;
            flex-direction: column-reverse;
            margin-top: 25%;
        }   
      }

 .supportboxmain{
          margin-top: 8%;
          margin-bottom: 8%;
 }

 #textsectionmain{
 margin-top: 25px;
 }


 .supporttextbox{
margin-left: 5%;
margin-bottom: 5%;
 }

.supportinfieldbox{
width: 80%;
height: 110%;
margin-left: 5% ;
}

.inputenametext{
padding-left: 5%;
padding-top: 1%;
}

.inputmessagetext{
padding-left: 5%;
padding-top: 2%;
padding-bottom: 2%;
}

.supporttextfield{
    width: 90%;
    max-width: 100%;
}