@media screen and (max-width: 1023px) {
  .topimagedesktop  {
         display: none;
  }
} 


@media screen and (min-width: 0px) and (max-width: 1023px) {
  .topimagemobile { 
      display: block;
      margin-top: 0px; 
  
      }   
}


@media screen and (min-width: 1024px)  {
  .topimagemobile { display: none; }  
}

@media screen and (max-width: 1510px) {
  .text-block h4 {
font-size: 30px;

  }
}


@media screen and (max-width: 1510px) {
  .text-block h4 {
font-size: 30px;

  }
}


@media screen and (max-width: 1165px) {
  .text-block {
top: 10%;

  }
}


.text-block {
  position: absolute;
  top: 40%;
  bottom: 60%;
  right: 55%;
  width: 40%;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
}

.text-block h4 {

  font-family: 'Ephesis';
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #f8e5ce;
  margin-bottom: 20px;

  
}

.text-block p{
  margin-bottom: 35px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

#topimagetitle{
  font-family: 'Ephesis';
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #f8e5ce;
}

#topimagepara{
  font-family: 'Lato';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

#topimageloginbutton{

  border: solid;
  border-color: #fff;
  border-width: 1px;
  background: transparent;
}


.chatbotIcon{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.imagesize{
width: 100%;
}

