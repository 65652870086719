.swipebar{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

#wishheading{
    width: 200px;
    height: 28px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: left;
    color: #37365a;
    margin-left: 10%;
}

#wishsubhead{

    width: 186px;
    height: 19px;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #362167;
    margin-top: 1%;
    margin-left: 10%;

}

#horizontallinelast{
  
  opacity: 0.4;
  background-color: #e9eaff;
  width: 100%;
}

.card{
    width: 100%;
    background-color: #f7f7f8;
}

.testimonialtext{
    font-family: 'OpenSans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  text-align: left;
  color: #7e8085;
  padding-left: 1%;
}



.subtestimonialtext{
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 550;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: #42495b;
    padding-bottom: 1%;
    padding-left: 1%;
 
}



.testimonialbox{
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #928a9f;
    padding-left: 5%;
    display: block;
    height: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
  }


  

  #testimonialboxname{
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #37365a;
    margin-left: 5%;
    margin-top: 2%;
  }


  #testimonialboxplace{
    font-family: 'OpenSans';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #37365a;
    margin-left: 5%;
  }



  @media screen and (min-width: 1024px) {
    .imageboxslider {
      display: 'flex';
      flex-direction: 'row';
             
    }
  } 

  .imageboxslider{
    background-color: white;
  }

  @media screen and (max-width: 1741px) {
    #testimonialboxname {
text-align: center;
         
    }
  } 

   @media screen and (max-width: 1741px) {
    #testimonialboxplace   {
text-align: center;
         
    }
  } 


  .caroussel-dots>*{
    background-color: #6d44ff;
  }

  
 

  @media screen and (min-width: 0px) and (max-width: 1600px) {
    #imagecenter{
      justify-content: center;
    }
  }

  
.testimonialimage{
padding: 15px;
width: 100%;
height: 100%;

}

#testimonialcontent{
width: 1000px;
}



