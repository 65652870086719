
@media screen and (max-width: 823px) {
  #h1heading{
font-size: 20px;

  }
}

@media screen and (max-width: 490px) {
  #h1heading{
font-size: 14px;

  }
}

@media screen and (max-width: 823px) {
  #h4heading{
font-size: 18px;

  }
}

@media screen and (max-width: 490px) {
  #h4heading{
font-size: 12px;

  }
}



#h1heading{

  font-family: 'Montserrat';
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #2c2e30;
  margin-bottom: 5%;
}


#h4heading{
  font-family: 'OpenSans';
  font-size: 20 px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #7e8085;
}

  .cardsmainbox{
    margin-left: 16px;
    margin-right: 16px;

  }


.bartext{

  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  text-align: center;
  height: 20%;
  font-size: 5px;
}

