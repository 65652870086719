.App {
    padding: 0px;
    margin: 0px;
    height: 100vh;
}   

.body{
    padding: 0px;
}


#websitebutton{
    border-radius: 92px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #37365a;

    height: 32px;

}

#invitationbutton{
    border-radius: 92px;
    background-image: linear-gradient(298deg, #874af1 106%, #605bb7 80%);
    font-family: 'Lato';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    height: 32px;
}

#protfoliotext{
    font-family: 'Montserrat';
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    margin-left: 5%;
    padding-top: 5%;
}

.stackmain{
    margin-left: 5%;
    margin-top: 2%;

}

.invitationboxmain{

    margin-top: 5%;
    margin-left: 48px;
    margin-left: 48px;
    margin-bottom: 40px;

}


#footerbox{
    background-image: linear-gradient(109deg, #5545a6 -4%, #353792 107%);
    padding-left: 0%;
    height: 450px;
}



.footerlugnalogo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 1%;
}

#footertext{
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #fff;
  }


#footercontainer{
    max-width: 100%;
    padding-left: 0%;
    padding-right: 0%;
}

.blue-magenta{
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    color: #37365a;
    text-align: center;
    padding-top: 40px;
}



@media screen and (min-width: 0px) and (max-width: 899px) {
    .lugnalogo{
         width: 169px;
         height: 38px;
    }   
}


.promotionalpagebody{
    background-color: #f8f9fa;
}


.textdecration{
text-decoration: none;

}


.invitationname{
    width: 100%;
  height: 22px;
  margin-top: 15%;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  text-align: center;
  color: #37365a;
  padding-left: 40%;
}

.invitationone{
width: 85%;
height: 100%;
border-radius: 10px;
}

.invitationtwo{
    width: 75%;
    height: 100%;
    border-radius: 10px;

}

.invitationthree{

    width: 70%;
    height: 100%;
    border-radius: 10px;

}

.cardmediathree{

    height: 750px;

}
