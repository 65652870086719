#blogstext{
   
  font-family: 'OpenSans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #7e8085;
}

#latestnewstext{
  font-family: 'Montserrat';
font-size: 34px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.24;
letter-spacing: normal;
text-align: left;
color: #42495b;
}


@media screen and (max-width: 1024px) {
.cardssectiontwo  {
      margin-top: 15px;

}
} 


#newscardtext{
font-family: 'Montserrat';
font-size: 20px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.7;
letter-spacing: normal;
text-align: left;
color: #2f281e;
}

.App {
  padding: 0px;
  margin: 0px;
  background-color: white;

}

.latestnewsspan{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5%;

}

.cardssection{
max-width: 400px;
}

.cardssectiontwo{
  max-width: 400px;
}