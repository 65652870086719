
@media screen and (max-width: 490px) {
  .websiteceationsh2 {
font-size: 16px;

  }
}


.websiteoptionboxmain{
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;

}

.optionsimagesize{
  width: 20%;
}