#createwebsitetexth1{
    font-family: 'Montserrat';
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

  }



  @media screen and (max-width: 1024px) {
    #createwebsitetexth1  {
      font-family: 'Montserrat';
      font-size: 45px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.9;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-bottom: 30px;
  
    }
  }
  
  .createwebsitebottomboxmain{

display: flex;
flex-wrap: wrap;
min-width: 300;
width: 100%;
margin-top: 0%;
  }


  .createlugnabutton{
    position: relative;
    padding: 4px;
    padding-top: 10px;
  }
 
