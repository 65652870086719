.App {
    padding: 0px;
    margin: 0px;
    background-color: white;
 
}

.body{
    padding: 0px;
}


.App .editor{
    width: 900px;
    height: 900px;
    margin-left: 20%;
    margin-top: 50px;
    border: 1px solid;
}

.editorClassName.rdw-editor-main {
    height: 350px;
}

.notranslate.public-DraftEditor-content {
    border: solid 1px #1c78ec;
}

.rdw-editor-toolbar.toolbarClassName {

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
 
}