
#savebutttonpopup{
  background: linear-gradient(to top left, #4f43e8 0%, #934bf3 100%);
}

#settingsbottombutton{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}


#addelementbottombutton{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 1%;

}
.MuiBox-root.css-1wnsr1i {
  width: 1000px;
  height: 100vh;
}

@media screen and (min-width: 1440px) and (max-width: 1855px) {
.MuiBox-root.css-1wnsr1i {
left: 65%;
}
}

@media screen and (min-width: 1855px) and (max-width: 3000px) {
  .MuiBox-root.css-1wnsr1i {
  left: 74%;
  
  }
  }

#settingspopuphead{
    display: flex;
    flex-direction: row;

}

.settingstoprow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.closeicon{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   font-size: 2rem;
   color: #495172;
}

.textfieldboxbottombutton{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 1%;
}

@media screen and (min-width: 1700px) {
  #deletebutton {
    margin-top: 15%;
  
  }
  }


@media screen and (min-width: 0px) and (max-width: 425px) {
    .MuiBox-root.css-1wnsr1i {
           width: 350px;
     
    }
  }

  @media screen and (min-width: 426px) and (max-width: 768px) {
    .MuiBox-root.css-1wnsr1i {
           width: 450px;
    }
  }


  @media screen and (min-width: 0px) and (max-width: 899px) {
    #mobileview {
   padding: 0%;
   margin: 1%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding-left: 0%;
   margin-left: 7%;
   
    }
  }


  @media screen and (min-width: 0px) and (max-width: 899px) {
    .iconimage {

      width: 38px;
    }
  }


  @media screen and (min-width: 899px){
    .bottomline   {
       margin-top: 22%;
    }
  }

  #textarea{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Lato';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #495172;
  }
 
#iconsize{
  height: 35px;
  width: 35px;
}

#addelementtext{
  text-align: center;
  margin-top: 15px;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #37365a;
  padding-right: 5px;
}

#addelementtextheading{
  font-family: 'Lato';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #495172;

}

#addelementbottombtn{
  font-family: 'SegoeUI';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #495172;
}

#addelementbottomnextbtn{
  font-family: 'SegoeUI';
  font-size: 14px;
  font-weight: normal;  
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.settingstext{
  font-family: 'Lato';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #495172;

}

#listtext{
  font-family: 'Lato';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #37365a;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.elementtext{
  font-family: 'Lato';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #37365a;
  padding-top: 16px;
}


.addelementtext{
  font-family: 'Lato';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #495172;
  padding-left: 32px;
  margin-bottom: 8px;
  

}

.fullwidthhrline{
margin-bottom: 1px;
border: solid 1px rgba(0, 0, 0, 0.05) ;
}

.addelementboxcontainer{

  margin-left: 32px;
  margin-right: 32px;
  margin-top: 40px;
}

.addelementbuttonhover:hover{
  background: linear-gradient(to right bottom, #934bf3, #4f43e8);
  color: #fff;
}


.iconimage{
  width:100%
   
}

.addelementbottombtn{
  font-family: 'SegoeUI';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #495172;
  right: 20px;
  background-color: #f4f5f7;
}

.addelementbottomnextbtn{
  font-family: 'SegoeUI';
  font-size: 14px;
  font-weight: normal;  
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  background: linear-gradient(to right bottom, #934bf3, #4f43e8);
  right: 10px;
}

.textfieldboxsettingstext{
  font-family: 'Lato';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #495172;
  margin-bottom: 8px;
  padding-left: 1px;
}


.iosswitch{
  margin: 8px;
}

.settiogicon{
  margin-right: 16px;

}

.accordiangap{
margin-top: 24px;
}

#accordainsummarycolor{
  background: #f2f2f2;
}

#deletebutton{

  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}


.accordianinputfield{
  width: 900;
  max-width: 100%;
}
