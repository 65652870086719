.footerlugnalogo{
display: flex;
flex-direction: row;
justify-content: center;
}

.footerinputfield{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footerpara{
    font-family: 'Lato';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-left: 35%;
    margin-right: 35%;
}

#footertext{
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #fff;
    padding-top: 1%;
    margin-bottom: 1%;

  }



#onchange{
    border-radius: 92px;
    background-image: linear-gradient(298deg, #605bb7 106%, #874af1 4%);
}


@media screen and (min-width: 0px) and (max-width: 580px) {
    #footerpara{
        margin-left: 10%;
        margin-right: 10%;
    }   
}

#promotionalpagebody{
    background-color: #f8f9fa;
}


#navbarconstructiontext{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 8%;
}

/* ////////////page number background css color changed from the root of MUI property/////// */
button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-textPrimary.Mui-selected.MuiPaginationItem-page.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
    background-color: #6d44ff;
    color: #fff;
}


@media screen and (min-width: 0px) and (max-width: 1440px) {
    .protfoliotext{
       padding-top: 20%;
    }   
}

.protfoliotext{
    padding-left: 5%;
    padding-top: 5%;
        font-family: 'Montserrat';
        font-size: 19px;
        font-weight: 800px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #212529;

}

#promotionwebpagetopbox{
    margin-top: 50px;
}

.websitepagestackmain{

    margin-left: 5%;
    margin-top: 2%;
}


.websitepageboxmain{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}

.websiteimage{

    border-radius: 10px;

}

.promotionalpagepagination{

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

}

.footerbox{
    background-image: linear-gradient(109deg, #5545a6 -4%, #353792 107%);
    padding-left: 0%;
    height: 450px;
    width: 100%;
}


.textfield{
    width: 800px;
    max-width: 100%;
    margin-left: 80px;
    margin-right: 80px;
    border-radius: 16px;
    background-color: white;
    margin-bottom: 1%;

}

.arrowicon{
    color: #50429f;

}

.fullwidthhrline{
width: 93%;
background-color: #8793ab;
}


.copyrighttext{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.90;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  

  .copyrightslogo{
    height: 20px;
    width: 40px;
    }

  