

.menuiconmain{
    margin-left: auto;
    margin-top: 5%;
    margin-right: 5%;
    color: white;
}

.drawerhrline{
    width: 100%;
    color: '#f7f7f8';

}

#drawerlistitembutton{
    justify-content: center;
    text-align: center;

}

#iconbutton{
    color: #bfbfbf;
    margin-left: auto;

}

.menuicon{
    color: #bfbfbf;
}

.drawerlistitemtext{
color: white;
}

