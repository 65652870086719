
.getstatedbutton{
    background: linear-gradient(to bottom left, #4f43e8 0%, #934bf3 100%);
    margin-left: 2%;
}


@media screen and (min-width: 0px) and (max-width: 899px) {
    .navbarlugnalogo {
        height: 20%;
        width: 30%;
    }   
  }

  .navbarlugnalogo{
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 899px) and (max-width: 1060px) {
    .navtab {
      width: 500px;
    }   
  }

  @media screen and (min-width: 899px) and (max-width: 1060px) {
    #navbar {
      width: 500px;
    }   
  }

  #loginbutton{

    border: solid;
    border-color: #fff;
    border-width: 1px;
    background: transparent;
  }

  @media screen and ( max-height: 699px ){
    .navbarheight{

      background-color: aquamarine;

    }
  }

.header-bg{
  background-color: white;
  color: black;
  
}


@media screen and ( min-width: 850px ) and ( max-height: 768px )
{
   .video-contain{
     margin-top:110px;  
    }
}


#navbarweddinginvitation{

color: inherit;

}

@media screen and (min-width: 900px) and (max-width: 1222px) {
.tabwidth {
  width: 420px;
}
}


#navbarloginbutton{
margin-left: auto;
margin-right: 20px;

}

.weddinginvtationnavlink{
  text-decoration: none;
}

#appbar{
  background: #362167;
}