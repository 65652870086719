.singleslidecard{
    justify-content: center;
    display: flex;
    flex-direction: row;
    padding: 0%;
}




@media screen and (min-width: 1068px) {
    .singleslide  {
       display: none;
         
    }
  } 

  @media screen and (min-width: 1068px) {
    .singleslidebtm  {
       display: none;
         
    }
  } 

  
  @media screen and (min-width: 1068px) {
    .blogstextpart  {
       display: none;
         
    }
  } 

.blogstext{
   font-family: 'OpenSans';
   font-size: 18px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.33;
   letter-spacing: normal;
   text-align: start;
   color: #7e8085;
}

.newstext{
   font-family: 'Montserrat';
   font-size: 24px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #42495b;
 
}

