@media screen and (max-width: 899px) {
  #createwebsiteboxtwo{
  display: flex;
  flex-direction: column-reverse;
  
  }
  }

.weddingbox{
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: justify;
}

.weddingbox p{
  font-family: 'Lato';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: justify;
  color: #848484;
}

.weddingbox h1{
  font-family: 'Montserrat';
font-size: 34px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.24;
letter-spacing: normal;
text-align: left;
color: #42495b;
}

.weddingbox h4{
  font-family: 'OpenSans';
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.38;
letter-spacing: normal;
text-align: left;
color: #7e8085;

}

#firstheading{
  font-family: 'OpenSans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #7e8085;
  margin-bottom: 1%;
}


#secondheading{
  font-family: 'Montserrat';
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #42495b;
  margin-bottom: 5%;
}

.createwebsiteboxmain{
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;

}

.createwebsitepara{
width: 80%;
}

