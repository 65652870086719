* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #000000;
  padding: 10px;
  overflow: auto;
}

.container {
  padding: 10px 150px;
  background-color: #f7f7f8;
  margin-top: 30px;
  padding-bottom: 50px;
}

p {
  font-size: 14px;
  text-align: justify;
}


[class*="col-"] {
  padding: 10px;
}

.card {
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0/20%), 0 2px 2px 0 rgb(0 0 0/14%),
    0 1px 5px 0 rgb(0 0 0/12%);
}

.mt-5 {
  margin-top: 50px;
}
.mb-10 {
  margin-bottom: 100px;
}

.testimonialtext{
padding-top: 5%;
}


.threeslide{
  margin-left: 8%;
  margin-right: 8%;
}




@media screen and (min-width: 801px) and (max-width: 1067px) {
  #blogstextpart{
    margin-left: 14%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 10px;
  }
}

@media screen and (max-width: 1067px) {
  .threeslide  {
     display: none;
       
  }
} 



