

/* mediaquery For mobile view */


@media screen and (min-width: 1024px) {
  .services  {
     display: none;
    
  }
} 

@media screen and (max-width: 1023px) {
  .services  {
    justify-content: center;
         
  }
} 


@media screen and (min-width: 1024px) {
  .quicklinks  {
     display: none;

  }
}


@media screen and (min-width: 1024px) {
  .sitemap  {
     display: none;
  
  }
}

/* mediaquery For Desktop view */


  @media screen and (min-width: 0px) and (max-width: 1023px) {
    .servicesdesktop { display: none; }   
  }
  @media screen and (min-width: 1024px)  {
    .servicesdesktop { display: block; }  
  }


  @media screen and (min-width: 0px) and (max-width: 1023px) {
    .quicklinksdesktop { display: none; }   
  }
  @media screen and (min-width: 1024px)  {
    .quicklinksdesktop { display: block; }   
  }


  @media screen and (min-width: 0px) and (max-width: 1023px) {
    .sitemapdesktop { display: none; }   
  }
  @media screen and (min-width: 1024px)  {
    .sitemapdesktop { display: block; }  
  }


.copyrighttext{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.90;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}


.newslettertext{
  font-family: 'Roboto';  
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-top: 3%;
  padding-bottom: 2%;
  margin-left: 10%;
  text-align: 'start';
  width: 100%;
  font-weight: 800;
  padding-left: 13%;
}

.footervendorsection{
  margin-left: 4%;
}

#footermenutext{
  font-family: Roboto;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  width: 100%;
  flex-shrink: 0;
  font-weight: 800;
  padding-left: 8%;
}


.listitemtext{
text-align: center;
}


.expandmoreicon{
  color: white;
}


.vendortext{
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding-left: 10%;
  padding-top: 8%;
  color: #fff;
}


.footermainbox{
  background-image: linear-gradient(180deg, #5545a6, #353792);
}


.footeritemone{
  background: none;
  height: 100%;
}


.footersectiontextfield{
  background-color: #ffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 2px;
  width: 800px;
  max-width: 100%;
    
}

.footernewslettertextfield{
  width: 75%;
  background-color: #ffff;
}


.ArrowCircleRightSharpIcon{
left: 25;
}

.ArrowCircleRightSharpIconcolor{
  color: '#50429f'
}

.copyrightslogo{
height: 20px;
width: 40px;
}

.footeravatar{
width: 28;
height: 28;
}

.growwithlugnatext{
color: white;
text-align: start;
padding-left: 10%;
padding-top: 1%;
width: 80%;
}

.footersitemapitem{
justify-content: 'center';
text-align: 'center';
}

.footersitemapdesktopitem{
  justify-content: 'center';
  text-align: 'center';
}

.footerlugnalogoimageitem{
  background: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;


}

.footerlugnaimage{
width: 50%;
height: 15%;
margin-top: 5%;
}

.footerlungatext{
color: white;
padding-top: 5%;
padding-bottom: 10%;
text-align: justify;
width: 80%;
}

.footerstack{
justify-content: center;
}

.footersignupbutton{
  background: linear-gradient(to bottom left, #4f43e8 0%, #934bf3 100%);
  top: 10%;

}
  
.footerhr{
  width: 93%;
}

.footerquicklinkslist{
width: 100%;
max-width: 360;
background: linear-gradient(to bottom left, #5545a6 *4%, #353792 107%);
color: white;
}

.listitemtext{
text-align: 'center';
}


.footerheadings{
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}


.footerlistitem{
right: 50;
}


.footerlistitemtext{
left: 1;
}

#accordianmain{
background-color: transparent;
color: white;
}

.Footermenumainbox{

  width: 100%;
  max-width: 360;
  color: white;
  background-color: transparent;

}

.footerlistitemmenuname{
right: 50px;
}

